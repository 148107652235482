export const light = {
  color: "#FFFFFF",
  text: "#000C37",
  bgColor: "#000C37",
  mainBgColor: "#EEEEEE",
};
export const dark = {
  color: "#000C37",
  text: "#FFFFFF",
  mainBgColor: "#000C37",
};
